import theme from 'styles/theme';

export default {
  container: (provided: any, state: any) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    backgroundColor: `${theme.colors.gray.light}`,
    fontSize: 14,
    padding: 0,
    borderBottom: state.menuIsOpen || state.hasValue
      ? `1px solid ${theme.colors.gray.dark}` : `1px solid ${theme.colors.white.opacity}`,
    borderRadius: 'none',
    opacity: 1,
    boxShadow: 'none',
    cursor: 'pointer',
    borderColor: `${theme.colors.gray.light}`,
    '&:hover': {
      borderColor: 'none',
    },
  }),
  valueContainer: (provided: any, state: any) => ({
    paddingLeft: 0,
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    height: 20,
    width: 20,
    padding: 0,
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '100%',
    alignSelf: 'center',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    width: 20,
    height: 20,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: `${theme.colors.gray.dark}`,
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0 2px',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: `${theme.colors.gray.light}`,
    opacity: 1,
    zIndex: 99,
    boxShadow: 'none',
    boxSizing: 'border-box',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    margin: '0 2px',
    height: 32,
    backgroundColor: '#007BFF',
    display: 'flex',
    alignItems: 'center',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    padding: 0,
    paddingLeft: 0,
    maxWidth: 98,
    margin: '0 8px',
    color: '#FFFFFF',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    fontWeight: '500',
    fontSize: 12,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    fill: '#FFFFFF',
    color: '#FFFFFF',
    height: 16,
    width: 16,
    margin: '0 4px',
    padding: 0,
    flexShrink: 0,
    cursor: 'pointer',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: `${theme.colors.gray.dark}`,
    margin: 0,
    paddingTop: 8,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    minHeight: 40,
    color: `${theme.colors.gray.dark}`,
    backgroundColor: state.isFocused ? theme.colors.gray.medium : theme.colors.gray.light,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }),
};
