import * as i from 'types';
import styled, { css } from 'styled-components';

export const InputFieldCss = css<InputProps>`
  width: 100%;
  height: 63px;
  padding: 32px 16px 15px 0px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray.dark};
  line-height: 15.4px;
  overflow: hidden;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  border-radius: 0;
  background-color: transparent;
  transition: border-color 0.2s;
  letter-spacing: -0.01em;

  ${({ type }) => type === 'textarea' && css`
    height: 175px;
    resize: none;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray.medium};
  `};

  ${({ isActive }) => isActive && css`
    border-color: ${({ theme }) => theme.colors.gray.dark};
  `}

  &:active,
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.gray.dark};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.medium};
  }

  &[readonly],
  &[disabled] {
    opacity: 0.4;
    border-left: 2px solid ${({ theme }) => theme.colors.gray.medium};
  }

  ${({ hasIcon }) => hasIcon && css`
    padding-left: 60px;
  `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  ${({ variant }) => variant === 'white' && css`
    color: ${({ theme }) => theme.colors.black};
    border-color: ${({ theme }) => theme.colors.gray.medium};
    background-color: ${({ theme }) => theme.colors.gray.light};

    &:active,
    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.black};
    }
  `}

  ${({ error }) => error && css`
    border-color: ${({ theme }) => theme.colors.red};

    &:active,
    &:focus {
      outline: none;
    }
  `};
`;

export type InputProps = Pick<i.DefaultInputProps, 'autoFocus'> & {
  error?: boolean;
  hasIcon?: boolean;
  isActive?: boolean;
  variant?: 'white';
  type?: i.InputTypes;
};

export const StyledInput = styled.input`
  ${InputFieldCss};
`;

export const InputIcon = styled.div<IconWrapperProps>`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding: 10px 0;
  cursor: pointer;

  svg {
    max-width: 30px;
    max-height: 100%;
    fill: ${({ theme }) => theme.colors.gray.medium};

    ${({ isActive }) => isActive && css`
      fill: ${({ theme }) => theme.colors.gray.medium};
  `}
  }
`;

type IconWrapperProps = Pick<i.DefaultInputProps, 'readOnly' | 'disabled'> & {
  isActive?:boolean;
};

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;

  ${({ $iconPosition }) => $iconPosition === 'right' && css`
    ${StyledInput} {
      padding: 32px 60px 15px 0px;
    }

    ${InputIcon} {
      left: auto;
      right: 16px;
    }
  `}
`;

type InputWrapperProps = Pick<i.DefaultInputProps, '$iconPosition'>;
