import styled, { css } from 'styled-components';
import * as i from 'types';

import { Input } from 'common/form';

export const ExtendedInput = styled(Input)<InputProps>`
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  color: ${({ theme }) => theme.colors.gray.dark};

  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.gray.dark};
  }

  ${({ isActive }) => isActive && css`
    color: ${({ theme }) => theme.colors.gray.dark};
  `}
`;

export type InputProps = Pick<i.DefaultInputProps, 'autoFocus'> & {
  isActive?: boolean;
};
