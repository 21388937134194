import * as i from 'types';
import styled, { css } from 'styled-components';

export const FormFieldContainer = styled.div<FormFieldContainerProps>`
  margin: ${({ margin }) => margin || '0 0 24px'};
`;

type FormFieldContainerProps = {
  margin?: string;
};

export const FormFieldsWrapper = styled.div<FormFieldsWrapperProps>`
  position: relative;
  flex-basis: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.gray.light};

  ${({ variant }) => variant === 'white' && css`
    background-color: ${({ theme }) => theme.colors.gray.light};
  `}

  ${({ type }) => type === 'textarea' && css`
    height: 100%;
  `}
`;

type FormFieldsWrapperProps = {
  variant?: 'white';
  type?: i.InputTypes;
};
